.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
:root {
  background-color: #282c34;
}
h1 {
  color: white;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-weight: bold;
}
button {
  max-width: 350px;
  margin-top: 2rem;
  margin-bottom: auto;
  width: 100%;
  background-color: #df142f;
  border: 3px solid #df142f;
  color: white;
  font-size: 1.5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
}
button:hover {
  background-color: transparent;
  color: #df142f;
  outline: none;
  cursor: pointer;
}
.startButton {
  fill: #df142f;
  stroke: #df142f;
  stroke-width: 1px;
}
.startButton:hover,
.startButton:focus {
  fill: transparent;
}
.liebe {
  color: white;
}
.App-link {
  color: #61dafb;
}
a {
  color: white;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
