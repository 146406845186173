.present {
  height: 200px;
  left: 0;
  margin: 0 auto;
  -webkit-perspective: 600px;
  perspective: 600px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 200px;
  --color1: #f2e6c0;
  --color2: #db553d;
}

.name {
  font-size: 4em;
  font-weight: 100;
  position: absolute;
  text-align: center;
  text-shadow: -3px 3px 0 rgba(229, 206, 129, 0.5);
  top: 50%;
  -webkit-transform: translate3d(0, -50%, 0) rotateY(0) rotateX(0);
  transform: translate3d(0, -50%, 0) rotateY(0) rotateX(0);
  -webkit-transition: -webkit-transform 2.5s cubic-bezier(0.22, 0.61, 0.36, 1);
  transition: -webkit-transform 2.5s cubic-bezier(0.22, 0.61, 0.36, 1);
  transition: transform 2.5s cubic-bezier(0.22, 0.61, 0.36, 1);
  transition: transform 2.5s cubic-bezier(0.22, 0.61, 0.36, 1),
    -webkit-transform 2.5s cubic-bezier(0.22, 0.61, 0.36, 1);
  width: 100%;
}

.instruction {
  bottom: -100px;
  left: 0;
  opacity: 1;
  position: absolute;
  text-align: center;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
  width: 100%;
}

.rotate-container {
  -webkit-animation: present-rotate 30s infinite linear;
  animation: present-rotate 30s infinite linear;
  height: 100%;
  -webkit-transform: rotateY(170deg);
  transform: rotateY(170deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

@-webkit-keyframes present-rotate {
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }
  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}

@keyframes present-rotate {
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }
  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}
.bottom,
.front,
.left,
.back,
.right {
  background-color: var(--color2);
  border: 1px solid rgba(0, 0, 0, 0.2);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.bottom {
  -webkit-transform: translateY(50%) rotateX(90deg);
  transform: translateY(50%) rotateX(90deg);
}

.front,
.left,
.back,
.right {
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.front:after,
.left:after,
.back:after,
.right:after,
.lid-top:after,
.lid-front:after,
.lid-left:after,
.lid-back:after,
.lid-right:after {
  background-color: var(--color1);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  content: '';
  height: 100%;
  left: calc(50% - 10px);
  position: absolute;
  -webkit-transform: translateZ(0.1px);
  transform: translateZ(0.1px);
  width: 20px;
}

.lid-top:before {
  background-color: var(--color1);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  content: '';
  height: 20px;
  left: 0;
  position: absolute;
  top: calc(50% - 10px);
  -webkit-transform: translateZ(0.1px);
  transform: translateZ(0.1px);
  width: 100%;
}

.left {
  -webkit-transform: translateX(-50%) rotateY(-90deg);
  transform: translateX(-50%) rotateY(-90deg);
}

.back {
  -webkit-transform: translateZ(-100px) rotateY(180deg) rotateX(0);
  transform: translateZ(-100px) rotateY(180deg) rotateX(0);
}

.right {
  -webkit-transform: translateX(50%) rotateY(90deg);
  transform: translateX(50%) rotateY(90deg);
}

.front {
  -webkit-transform: translateZ(100px);
  transform: translateZ(100px);
}

.lid {
  -webkit-animation: lid-animation 3.5s 1s infinite;
  animation: lid-animation 3.5s 1s infinite;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}

@-webkit-keyframes lid-animation {
  0% {
    -webkit-transform: translate3d(0, 0, 0) rotateX(0);
    transform: translate3d(0, 0, 0) rotateX(0);
  }
  5% {
    -webkit-transform: translate3d(0, -10px, -5px) rotateX(5deg);
    transform: translate3d(0, -10px, -5px) rotateX(5deg);
  }
  10% {
    -webkit-transform: translate3d(0, -10px, 5px) rotateX(-5deg);
    transform: translate3d(0, -10px, 5px) rotateX(-5deg);
  }
  15% {
    -webkit-transform: translate3d(0, -10px, -5px) rotateX(5deg);
    transform: translate3d(0, -10px, -5px) rotateX(5deg);
  }
  20% {
    -webkit-transform: translate3d(0, -10px, 5px) rotateX(-5deg);
    transform: translate3d(0, -10px, 5px) rotateX(-5deg);
  }
  25% {
    -webkit-transform: translate3d(0, -10px, -5px) rotateX(5deg);
    transform: translate3d(0, -10px, -5px) rotateX(5deg);
  }
  30% {
    -webkit-transform: translate3d(0, 0, 0) rotateX(0);
    transform: translate3d(0, 0, 0) rotateX(0);
  }
}

@keyframes lid-animation {
  0% {
    -webkit-transform: translate3d(0, 0, 0) rotateX(0);
    transform: translate3d(0, 0, 0) rotateX(0);
  }
  5% {
    -webkit-transform: translate3d(0, -10px, -5px) rotateX(5deg);
    transform: translate3d(0, -10px, -5px) rotateX(5deg);
  }
  10% {
    -webkit-transform: translate3d(0, -10px, 5px) rotateX(-5deg);
    transform: translate3d(0, -10px, 5px) rotateX(-5deg);
  }
  15% {
    -webkit-transform: translate3d(0, -10px, -5px) rotateX(5deg);
    transform: translate3d(0, -10px, -5px) rotateX(5deg);
  }
  20% {
    -webkit-transform: translate3d(0, -10px, 5px) rotateX(-5deg);
    transform: translate3d(0, -10px, 5px) rotateX(-5deg);
  }
  25% {
    -webkit-transform: translate3d(0, -10px, -5px) rotateX(5deg);
    transform: translate3d(0, -10px, -5px) rotateX(5deg);
  }
  30% {
    -webkit-transform: translate3d(0, 0, 0) rotateX(0);
    transform: translate3d(0, 0, 0) rotateX(0);
  }
}
.lid-top,
.lid-left,
.lid-back,
.lid-right,
.lid-front {
  background-color: var(--color2);
  border: 1px solid rgba(0, 0, 0, 0.2);
  left: -5px;
  opacity: 1;
  position: absolute;
  top: 0;
  width: 210px;
}

.lid-top {
  height: 210px;
  top: -5px;
  -webkit-transform: translateY(-50%) rotateX(90deg);
  transform: translateY(-50%) rotateX(90deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.lid-left,
.lid-back,
.lid-right,
.lid-front {
  height: 40px;
  top: -5px;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.lid-left {
  -webkit-transform: translateX(-50%) rotateY(-90deg);
  transform: translateX(-50%) rotateY(-90deg);
}

.lid-back {
  -webkit-transform: translateZ(-105px) rotateY(180deg);
  transform: translateZ(-105px) rotateY(180deg);
}

.lid-right {
  -webkit-transform: translateX(50%) rotateY(90deg);
  transform: translateX(50%) rotateY(90deg);
}

.lid-front {
  -webkit-transform: translateZ(105px);
  transform: translateZ(105px);
}

.present:hover .lid {
  -webkit-animation: none;
  animation: none;
  -webkit-transform: translate3d(0, -40px, -10px) rotateX(10deg);
  transform: translate3d(0, -40px, -10px) rotateX(10deg);
}

.present.open .name {
  -webkit-transform: translate3d(0, -50%, 10px) rotateY(1080deg) rotateX(10deg);
  transform: translate3d(0, -50%, 10px) rotateY(1080deg) rotateX(10deg);
}
.present.open .instruction {
  opacity: 0;
}
.present.open .left {
  -webkit-transform: translateX(-50%) rotateY(-90deg) rotateX(-90deg);
  transform: translateX(-50%) rotateY(-90deg) rotateX(-90deg);
}
.present.open .back {
  -webkit-transform: translateZ(-100px) rotateY(180deg) rotateX(-90deg);
  transform: translateZ(-100px) rotateY(180deg) rotateX(-90deg);
}
.present.open .right {
  -webkit-transform: translateX(50%) rotateY(90deg) rotateX(-90deg);
  transform: translateX(50%) rotateY(90deg) rotateX(-90deg);
}
.present.open .front {
  -webkit-transform: translateZ(100px) rotateX(-90deg);
  transform: translateZ(100px) rotateX(-90deg);
}
.present.open .lid {
  -webkit-animation: none;
  animation: none;
  -webkit-transform: translate3d(0, -120px, -120px) rotateX(50deg);
  transform: translate3d(0, -120px, -120px) rotateX(50deg);
}

canvas {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}
